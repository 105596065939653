import React, { useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Categories from './components/Categories';
import JobList from './components/JobList';
import JobSearch from './components/JobSearch';
import { ProfilePage } from './components/profile/ProfilePage';
import Footer from './components/Footer';
import JobForm from './components/jobs/JobForm';
import Dashboard from './components/admin/Dashboard';
import { useAuth } from './contexts/AuthContext';
import { Job } from './types';

export function App() {
  const { user } = useAuth();
  const [isPostingJob, setIsPostingJob] = useState(false);
  const [isSearchingJobs, setIsSearchingJobs] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handlePostJob = () => {
    if (user?.role === 'employer') {
      setIsPostingJob(true);
      setIsSearchingJobs(false);
      setShowProfile(false);
      setShowAdmin(false);
    } else {
      alert('Please login as an employer to post jobs');
    }
  };

  const handleFindWork = () => {
    setIsSearchingJobs(true);
    setIsPostingJob(false);
    setShowProfile(false);
    setShowAdmin(false);
  };

  const handleHomeClick = () => {
    setIsPostingJob(false);
    setIsSearchingJobs(false);
    setShowProfile(false);
    setShowAdmin(false);
    setFilteredJobs([]);
    setSearchPerformed(false);
  };

  const handleProfileClick = () => {
    setShowProfile(true);
    setIsPostingJob(false);
    setIsSearchingJobs(false);
    setShowAdmin(false);
  };

  const handleAdminClick = () => {
    setShowAdmin(true);
    setShowProfile(false);
    setIsPostingJob(false);
    setIsSearchingJobs(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header 
        onHomeClick={handleHomeClick} 
        onProfileClick={handleProfileClick}
        onAdminClick={handleAdminClick}
      />
      <main>
        {showAdmin ? (
          <Dashboard />
        ) : isPostingJob ? (
          <div className="pt-24 pb-16">
            <div className="max-w-4xl mx-auto px-4">
              <h1 className="text-3xl font-bold text-gray-900 mb-8">Post a New Job</h1>
              <JobForm 
                onSubmit={async (jobData) => {
                  try {
                    const response = await fetch('/api/jobs', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        ...jobData,
                        clientId: user?.id,
                        status: 'open',
                        createdAt: new Date(),
                      }),
                    });
                    if (response.ok) {
                      setIsPostingJob(false);
                    }
                  } catch (error) {
                    console.error('Error creating job:', error);
                  }
                }}
                onCancel={() => setIsPostingJob(false)}
              />
            </div>
          </div>
        ) : showProfile ? (
          <ProfilePage />
        ) : (
          <>
            <Hero onPostJob={handlePostJob} onFindWork={handleFindWork} />
            {isSearchingJobs ? (
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <JobSearch 
                  onSearch={(jobs, searched) => {
                    setFilteredJobs(jobs);
                    setSearchPerformed(searched);
                  }} 
                />
                <JobList jobs={filteredJobs} searchPerformed={searchPerformed} />
              </div>
            ) : (
              <>
                <Categories />
                <JobList />
              </>
            )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default App;