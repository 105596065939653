import React, { useState } from 'react';
import { Clock, MapPin, Wrench, Calendar } from 'lucide-react';
import { createJob } from '../../db';
import { Job } from '../../types';
import { moroccanCities } from '../../data/cities';

interface JobFormProps {
  onSubmit: (data: Partial<Job>) => void;
  onCancel: () => void;
}

export default function JobForm({ onSubmit, onCancel }: JobFormProps) {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    salary: {
      amount: '',
      type: 'hourly' as 'hourly' | 'daily' | 'weekly' | 'monthly',
      currency: 'MAD'
    },
    category: '',
    skills: '',
    district: '',
    type: 'onsite' as 'remote' | 'onsite' | 'hybrid',
    workSchedule: {
      type: 'fixed' as 'fixed' | 'flexible',
      hours: {
        start: '09:00',
        end: '17:00'
      },
      daysPerWeek: '5',
      duration: '',
      durationType: 'days' as 'days' | 'weeks' | 'months'
    },
    benefits: {
      equipment: false,
      transport: false,
      meals: false,
      other: ''
    }
  });

  const [selectedCity, setSelectedCity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const jobData = {
        title: formData.title,
        description: formData.description,
        salary: {
          amount: Number(formData.salary.amount),
          type: formData.salary.type,
          currency: 'MAD'
        },
        category: formData.category,
        skills: formData.skills.split(',').map(s => s.trim()),
        location: selectedCity && formData.district 
          ? `${formData.district}, ${selectedCity}`
          : selectedCity || formData.location,
        type: formData.type,
        workSchedule: formData.workSchedule,
        benefits: formData.benefits,
        status: 'open',
        createdAt: new Date(),
      };

      const jobId = await createJob(jobData as Omit<Job, 'id'>);
      if (jobId) {
        onSubmit(jobData);
        // Afficher un message de succès
        alert('Votre annonce a été publiée avec succès !');
        // Rediriger vers la page principale
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error creating job:', error);
      alert('Une erreur est survenue lors de la publication de l\'annonce. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const city = e.target.value;
    setSelectedCity(city);
    setFormData(prev => ({
      ...prev,
      district: '',
      location: city
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-sm">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Job Title
        </label>
        <input
          type="text"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          rows={6}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Salary Amount (MAD)
          </label>
          <input
            type="number"
            value={formData.salary.amount}
            onChange={(e) => setFormData({ 
              ...formData, 
              salary: { ...formData.salary, amount: e.target.value }
            })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Payment Type
          </label>
          <select
            value={formData.salary.type}
            onChange={(e) => setFormData({ 
              ...formData, 
              salary: { ...formData.salary, type: e.target.value as 'hourly' | 'daily' | 'weekly' | 'monthly' }
            })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            required
          >
            <option value="hourly">Per Hour</option>
            <option value="daily">Per Day</option>
            <option value="weekly">Per Week</option>
            <option value="monthly">Per Month</option>
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category
        </label>
        <select
          value={formData.category}
          onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
          required
        >
          <option value="">Select a category</option>
          <option value="development">Development</option>
          <option value="design">Design</option>
          <option value="digital-marketing">Digital Marketing</option>
          <option value="content-creation">Content Creation</option>
          <option value="mechanics">Mechanics</option>
          <option value="construction">Construction</option>
          <option value="carpentry">Carpentry</option>
          <option value="tailoring">Tailoring</option>
          <option value="cooking">Cooking</option>
          <option value="drivers">Drivers</option>
          <option value="delivery">Delivery</option>
          <option value="house-cleaning">House Cleaning</option>
          <option value="gardening">Gardening</option>
          <option value="laundry">Laundry</option>
          <option value="childcare">Childcare</option>
          <option value="security">Security</option>
          <option value="sales">Sales</option>
          <option value="personal-training">Personal Training</option>
          <option value="tech-support">Tech Support</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Required Skills (comma-separated)
        </label>
        <input
          type="text"
          value={formData.skills}
          onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            City
          </label>
          <select
            value={selectedCity}
            onChange={handleCityChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            required
          >
            <option value="">Select a city</option>
            <option value="casablanca">Casablanca</option>
            <option value="rabat">Rabat</option>
            <option value="marrakech">Marrakech</option>
            {moroccanCities.other.map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
        </div>

        {selectedCity && moroccanCities[selectedCity as keyof typeof moroccanCities]?.districts && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              District
            </label>
            <select
              value={formData.district}
              onChange={(e) => setFormData({ ...formData, district: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
              required
            >
              <option value="">Select a district</option>
              {moroccanCities[selectedCity as keyof typeof moroccanCities].districts.map(district => (
                <option key={district} value={district}>{district}</option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Work Type
        </label>
        <select
          value={formData.type}
          onChange={(e) => setFormData({ ...formData, type: e.target.value as 'remote' | 'onsite' | 'hybrid' })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
          required
        >
          <option value="onsite">On-site</option>
          <option value="remote">Remote</option>
          <option value="hybrid">Hybrid</option>
        </select>
      </div>

      <div className="space-y-4">
        <h3 className="font-medium text-gray-900 flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          Work Schedule
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Schedule Type
            </label>
            <select
              value={formData.workSchedule.type}
              onChange={(e) => setFormData({
                ...formData,
                workSchedule: {
                  ...formData.workSchedule,
                  type: e.target.value as 'fixed' | 'flexible'
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            >
              <option value="fixed">Fixed Hours</option>
              <option value="flexible">Flexible Hours</option>
            </select>
          </div>

          {formData.workSchedule.type === 'fixed' && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Time
                </label>
                <input
                  type="time"
                  value={formData.workSchedule.hours.start}
                  onChange={(e) => setFormData({
                    ...formData,
                    workSchedule: {
                      ...formData.workSchedule,
                      hours: {
                        ...formData.workSchedule.hours,
                        start: e.target.value
                      }
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Time
                </label>
                <input
                  type="time"
                  value={formData.workSchedule.hours.end}
                  onChange={(e) => setFormData({
                    ...formData,
                    workSchedule: {
                      ...formData.workSchedule,
                      hours: {
                        ...formData.workSchedule.hours,
                        end: e.target.value
                      }
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Days per Week
            </label>
            <input
              type="number"
              min="1"
              max="7"
              value={formData.workSchedule.daysPerWeek}
              onChange={(e) => setFormData({
                ...formData,
                workSchedule: {
                  ...formData.workSchedule,
                  daysPerWeek: e.target.value
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Duration
            </label>
            <input
              type="number"
              min="1"
              value={formData.workSchedule.duration}
              onChange={(e) => setFormData({
                ...formData,
                workSchedule: {
                  ...formData.workSchedule,
                  duration: e.target.value
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Duration Type
            </label>
            <select
              value={formData.workSchedule.durationType}
              onChange={(e) => setFormData({
                ...formData,
                workSchedule: {
                  ...formData.workSchedule,
                  durationType: e.target.value as 'days' | 'weeks' | 'months'
                }
              })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            >
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
            </select>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="font-medium text-gray-900 flex items-center">
          <Wrench className="h-5 w-5 mr-2" />
          Benefits & Equipment
        </h3>
        
        <div className="space-y-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.benefits.equipment}
              onChange={(e) => setFormData({
                ...formData,
                benefits: {
                  ...formData.benefits,
                  equipment: e.target.checked
                }
              })}
              className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
            />
            <span className="ml-2 text-sm text-gray-700">Equipment Provided</span>
          </label>
          
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.benefits.transport}
              onChange={(e) => setFormData({
                ...formData,
                benefits: {
                  ...formData.benefits,
                  transport: e.target.checked
                }
              })}
              className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
            />
            <span className="ml-2 text-sm text-gray-700">Transport Provided</span>
          </label>
          
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.benefits.meals}
              onChange={(e) => setFormData({
                ...formData,
                benefits: {
                  ...formData.benefits,
                  meals: e.target.checked
                }
              })}
              className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
            />
            <span className="ml-2 text-sm text-gray-700">Meals Provided</span>
          </label>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Additional Benefits or Requirements
          </label>
          <textarea
            value={formData.benefits.other}
            onChange={(e) => setFormData({
              ...formData,
              benefits: {
                ...formData.benefits,
                other: e.target.value
              }
            })}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
            placeholder="Describe any additional benefits or specific requirements..."
          />
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? 'Publishing...' : 'Post Job'}
        </button>
      </div>
    </form>
  );
}