import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

export default function Footer() {
  const { t } = useLanguage();

  return (
    <footer className="bg-gray-900 text-gray-300 py-8 md:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div className="col-span-2 md:col-span-1">
            <h3 className="text-white text-lg font-semibold mb-4">{t('app.name')}</h3>
            <p className="text-sm">{t('app.description')}</p>
          </div>
          <div>
            <h4 className="text-white font-medium mb-4">{t('account.employer')}</h4>
            <ul className="space-y-2 text-sm">
              <li>{t('footer.howToHire')}</li>
              <li>{t('footer.pricing')}</li>
              <li>{t('footer.enterprise')}</li>
              <li>{t('footer.success')}</li>
            </ul>
          </div>
          <div>
            <h4 className="text-white font-medium mb-4">{t('account.worker')}</h4>
            <ul className="space-y-2 text-sm">
              <li>{t('footer.howToWork')}</li>
              <li>{t('footer.contracts')}</li>
              <li>{t('footer.profile')}</li>
              <li>{t('footer.success')}</li>
            </ul>
          </div>
          <div>
            <h4 className="text-white font-medium mb-4">{t('footer.resources')}</h4>
            <ul className="space-y-2 text-sm">
              <li>{t('footer.help')}</li>
              <li>{t('footer.blog')}</li>
              <li>{t('footer.community')}</li>
              <li>{t('footer.contact')}</li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
          © 2024 {t('app.name')}. {t('footer.rights')}
        </div>
      </div>
    </footer>
  );
}