import React, { useState, useEffect, useRef } from 'react';
import { Send, X, Phone, Video, MoreVertical } from 'lucide-react';
import { Message, Chat, User } from '../../types';
import { db } from '../../db';
import { useAuth } from '../../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

interface ChatWindowProps {
  receiverId: string;
  jobId?: string | null;
  onClose: () => void;
}

export default function ChatWindow({ receiverId, jobId, onClose }: ChatWindowProps) {
  const { user: currentUser } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [receiver, setReceiver] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [chat, setChat] = useState<Chat | null>(null);

  useEffect(() => {
    const loadChatData = async () => {
      if (!currentUser) return;

      try {
        // Charger les informations du destinataire
        const receiverData = await db.users.get(receiverId);
        setReceiver(receiverData || null);

        // Trouver ou créer une conversation
        const participants = [currentUser.id, receiverId].sort();
        let existingChat = await db.chats
          .where('participants')
          .equals(participants)
          .first();

        if (!existingChat) {
          // Créer un nouveau chat
          const newChatId = await db.chats.add({
            participants,
            jobId,
            lastMessageDate: new Date(),
            unreadCount: 0,
            createdAt: new Date()
          });
          existingChat = await db.chats.get(newChatId);
        }

        if (existingChat) {
          setChat(existingChat);
          // Charger les messages
          const chatMessages = await db.messages
            .where('chatId')
            .equals(existingChat.id)
            .toArray();

          // Trier les messages par date
          chatMessages.sort((a, b) => 
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
          
          setMessages(chatMessages);

          // Marquer les messages comme lus
          const unreadMessages = chatMessages.filter(
            msg => msg.receiverId === currentUser.id && !msg.read
          );

          if (unreadMessages.length > 0) {
            await Promise.all(
              unreadMessages.map(msg =>
                db.messages.update(msg.id!, { read: true })
              )
            );

            // Mettre à jour le compteur de messages non lus
            await db.chats.update(existingChat.id, {
              unreadCount: Math.max(0, (existingChat.unreadCount || 0) - unreadMessages.length)
            });
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error loading chat:', error);
        setIsLoading(false);
      }
    };

    loadChatData();
  }, [currentUser, receiverId, jobId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !currentUser || !chat) return;

    const messageData = {
      chatId: chat.id,
      senderId: currentUser.id,
      receiverId,
      content: newMessage.trim(),
      createdAt: new Date(),
      read: false
    };

    try {
      const messageId = await db.messages.add(messageData);
      
      // Mettre à jour le chat
      await db.chats.update(chat.id, {
        lastMessage: newMessage.trim(),
        lastMessageDate: new Date(),
        unreadCount: (chat.unreadCount || 0) + 1
      });

      setMessages([...messages, { ...messageData, id: messageId as string }]);
      setNewMessage('');

      // Envoyer une notification
      if ('Notification' in window && Notification.permission === 'granted') {
        new Notification('Nouveau message', {
          body: `${currentUser.name}: ${newMessage}`,
          icon: '/logo.png'
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed bottom-0 right-4 w-96 bg-white rounded-t-lg shadow-lg border border-gray-200">
        <div className="p-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 right-4 w-96 bg-white rounded-t-lg shadow-lg border border-gray-200 flex flex-col max-h-[600px]">
      {/* Header */}
      <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-emerald-50">
        <div className="flex items-center">
          {receiver?.avatar ? (
            <img
              src={receiver.avatar}
              alt={receiver.name}
              className="w-10 h-10 rounded-full mr-3"
            />
          ) : (
            <div className="w-10 h-10 rounded-full bg-emerald-200 flex items-center justify-center text-emerald-600 font-semibold">
              {receiver?.name?.charAt(0)}
            </div>
          )}
          <div>
            <h3 className="font-medium text-gray-900">{receiver?.name}</h3>
            <p className="text-xs text-gray-500">{receiver?.jobTitle}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-2 rounded-full hover:bg-gray-100"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${
              message.senderId === currentUser?.id ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg px-4 py-2 ${
                message.senderId === currentUser?.id
                  ? 'bg-emerald-500 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              <p className="text-sm">{message.content}</p>
              <p className="text-xs mt-1 opacity-75">
                {formatDistanceToNow(new Date(message.createdAt), {
                  addSuffix: true,
                  locale: fr
                })}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input */}
      <form onSubmit={handleSend} className="p-4 border-t border-gray-200">
        <div className="flex gap-2">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Écrivez votre message..."
            className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500"
          />
          <button
            type="submit"
            disabled={!newMessage.trim()}
            className="bg-emerald-500 text-white p-2 rounded-lg hover:bg-emerald-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>
    </div>
  );
}