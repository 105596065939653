import React, { useState, useEffect } from 'react';
import { 
  Users, Briefcase, DollarSign, LineChart, 
  AlertTriangle, CheckCircle, Clock, Ban, Lock
} from 'lucide-react';
import { db } from '../../db/schema';
import { useAuth } from '../../contexts/AuthContext';
import AdminNav from './AdminNav';
import StatsCard from './StatsCard';
import RecentUsers from './RecentUsers';
import RecentJobs from './RecentJobs';
import PaymentChart from './PaymentChart';

const ADMIN_EMAIL = 'admin@ajitkhdm.com';
const ADMIN_PASSWORD = 'admin123456'; // In production, this should be properly hashed and stored securely

export default function Dashboard() {
  const { user } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalJobs: 0,
    totalPayments: 0,
    activeJobs: 0,
    pendingVerifications: 0,
    completedJobs: 0,
    totalRevenue: 0,
    bannedUsers: 0
  });

  useEffect(() => {
    const loadStats = async () => {
      const users = await db.users.toArray();
      const jobs = await db.jobs.toArray();
      const payments = await db.payments.toArray();

      setStats({
        totalUsers: users.length,
        totalJobs: jobs.length,
        totalPayments: payments.length,
        activeJobs: jobs.filter(job => job.status === 'open').length,
        pendingVerifications: users.filter(user => !user.isEmailVerified).length,
        completedJobs: jobs.filter(job => job.status === 'completed').length,
        totalRevenue: payments.reduce((sum, payment) => sum + payment.platformFee, 0),
        bannedUsers: users.filter(user => user.banned).length
      });
    };

    if (isAuthenticated) {
      loadStats();
    }
  }, [isAuthenticated]);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Invalid password');
    }
  };

  // Only allow access if user is admin
  if (!user || user.email !== ADMIN_EMAIL) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <AlertTriangle className="h-12 w-12 text-red-500 mx-auto mb-4" />
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Access Denied</h1>
          <p className="text-gray-600">You don't have permission to access this area.</p>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <div className="text-center mb-6">
            <Lock className="h-12 w-12 text-emerald-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-gray-900">Admin Authentication</h1>
            <p className="text-gray-600 mt-2">Please enter your admin password to continue</p>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
              {error}
            </div>
          )}

          <form onSubmit={handleLogin}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500 mb-4"
              placeholder="Enter admin password"
              required
            />
            <button
              type="submit"
              className="w-full bg-emerald-600 text-white py-2 rounded-md hover:bg-emerald-700 transition-colors"
            >
              Access Dashboard
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminNav />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <StatsCard
            title="Total Users"
            value={stats.totalUsers}
            icon={Users}
            trend={+12}
          />
          <StatsCard
            title="Active Jobs"
            value={stats.activeJobs}
            icon={Briefcase}
            trend={+5}
          />
          <StatsCard
            title="Platform Revenue"
            value={`${stats.totalRevenue.toLocaleString()} MAD`}
            icon={DollarSign}
            trend={+8}
          />
          <StatsCard
            title="Completed Jobs"
            value={stats.completedJobs}
            icon={CheckCircle}
            trend={+15}
          />
        </div>

        {/* Additional Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-yellow-50 p-6 rounded-lg">
            <div className="flex items-center">
              <Clock className="h-8 w-8 text-yellow-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold text-yellow-700">Pending Verifications</h3>
                <p className="text-2xl font-bold text-yellow-800">{stats.pendingVerifications}</p>
              </div>
            </div>
          </div>
          
          <div className="bg-red-50 p-6 rounded-lg">
            <div className="flex items-center">
              <Ban className="h-8 w-8 text-red-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold text-red-700">Banned Users</h3>
                <p className="text-2xl font-bold text-red-800">{stats.bannedUsers}</p>
              </div>
            </div>
          </div>
          
          <div className="bg-emerald-50 p-6 rounded-lg">
            <div className="flex items-center">
              <DollarSign className="h-8 w-8 text-emerald-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold text-emerald-700">Total Transactions</h3>
                <p className="text-2xl font-bold text-emerald-800">{stats.totalPayments}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Charts Row */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Revenue Trend</h2>
            <PaymentChart />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">User Growth</h2>
            {/* Add user growth chart component */}
          </div>
        </div>

        {/* Recent Activity */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <RecentUsers />
          <RecentJobs />
        </div>
      </div>
    </div>
  );
}