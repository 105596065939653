import Dexie from 'dexie';
import type { Table } from 'dexie';
import type { User, Job, Portfolio, Review, Message, Chat, Payment } from '../types';

export class AjiTkhdmDB extends Dexie {
  users!: Table<User>;
  jobs!: Table<Job>;
  portfolios!: Table<Portfolio>;
  reviews!: Table<Review>;
  messages!: Table<Message>;
  chats!: Table<Chat>;
  payments!: Table<Payment>;

  constructor() {
    super('ajitkhdm');
    
    this.version(1).stores({
      users: '++id, email, role, name, location, createdAt',
      jobs: '++id, clientId, workerId, status, category, type, location, &createdAt',
      portfolios: '++id, userId, &createdAt',
      reviews: '++id, authorId, rating, &createdAt',
      messages: '++id, chatId, senderId, receiverId, &createdAt',
      chats: '++id, *participants, jobId, &lastMessageDate',
      payments: '++id, jobId, employerId, workerId, status, &createdAt'
    });
  }
}

export const db = new AjiTkhdmDB();