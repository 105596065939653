import React from 'react';
import { Download } from 'lucide-react';
import { ProfileFormData } from './types';

interface ProfileViewProps {
  formData: ProfileFormData;
  user: any;
}

export function ProfileView({ formData, user }: ProfileViewProps) {
  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">{user?.name}</h1>
        <p className="text-emerald-600 font-medium">{formData.jobTitle || 'Add your job title'}</p>
      </div>

      <div>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">About Me</h2>
        <p className="text-gray-600">{formData.bio || 'Tell others about yourself...'}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Skills</h2>
          <div className="flex flex-wrap gap-2">
            {formData.skills.split(',').map((skill) => (
              <span
                key={skill}
                className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm"
              >
                {skill.trim()}
              </span>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Languages</h2>
          <div className="flex flex-wrap gap-2">
            {formData.languages.split(',').map((language) => (
              <span
                key={language}
                className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm"
              >
                {language.trim()}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Hourly Rate</h2>
        <p className="text-2xl font-bold text-emerald-600">
          {formData.hourlyRate ? `${formData.hourlyRate} MAD/hr` : 'Add your rate'}
        </p>
      </div>

      <div>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Education</h2>
        <div className="prose prose-emerald">
          {formData.education || 'Add your education history...'}
        </div>
      </div>

      <div>
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Work Experience</h2>
        <div className="prose prose-emerald">
          {formData.experience || 'Add your work experience...'}
        </div>
      </div>

      <div className="flex justify-end">
        <button className="flex items-center text-emerald-600 hover:text-emerald-700">
          <Download className="h-5 w-5 mr-2" />
          Download Resume
        </button>
      </div>
    </div>
  );
}