import React from 'react';
import { 
  Code, Paintbrush, PenTool, Camera, Globe2, BookOpen, 
  Megaphone, Languages, Car, Wrench, UtensilsCrossed, 
  Scissors, Home, Warehouse, TreePine, ShoppingBag, Baby,
  Building2, Hammer, Shield, Shirt, Dumbbell, Laptop
} from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';

export default function Categories() {
  const { t } = useLanguage();

  const categories = [
    { icon: Code, name: t('category.development'), count: "1.2k" },
    { icon: Paintbrush, name: t('category.design'), count: "850" },
    { icon: Globe2, name: t('category.marketing'), count: "923" },
    { icon: PenTool, name: t('category.content'), count: "645" },
    { icon: Wrench, name: t('category.mechanics'), count: "432" },
    { icon: Building2, name: t('category.construction'), count: "567" },
    { icon: Hammer, name: t('category.carpentry'), count: "234" },
    { icon: Scissors, name: t('category.tailoring'), count: "321" },
    { icon: UtensilsCrossed, name: t('category.cooking'), count: "445" },
    { icon: Car, name: t('category.drivers'), count: "789" },
    { icon: Warehouse, name: t('category.delivery'), count: "678" },
    { icon: Home, name: t('category.cleaning'), count: "890" },
    { icon: TreePine, name: t('category.gardening'), count: "234" },
    { icon: Shirt, name: t('category.laundry'), count: "456" },
    { icon: Baby, name: t('category.childcare'), count: "567" },
    { icon: Shield, name: t('category.security'), count: "345" },
    { icon: ShoppingBag, name: t('category.sales'), count: "678" },
    { icon: Dumbbell, name: t('category.training'), count: "234" },
    { icon: Laptop, name: t('category.tech'), count: "445" }
  ];

  return (
    <div className="bg-gray-50 py-8 md:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6 md:mb-8">{t('categories.title')}</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 md:gap-4">
          {categories.map(({ icon: Icon, name, count }) => (
            <div key={name} className="bg-white rounded-lg p-4 md:p-6 hover:shadow-md transition-shadow cursor-pointer border border-gray-100">
              <Icon className="h-6 w-6 md:h-8 md:w-8 text-emerald-600 mb-2 md:mb-3" />
              <h3 className="font-semibold text-gray-900 text-sm md:text-base mb-1">{name}</h3>
              <p className="text-xs md:text-sm text-gray-500">{count} {t('categories.jobCount')}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}