import validator from 'validator';
import { parsePhoneNumber } from 'libphonenumber-js';

const JWT_SECRET = 'your-secret-key'; // In production, use environment variable

// Browser-compatible base64url implementation
const base64url = {
  encode: (str: string) => {
    return btoa(str)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  },
  decode: (str: string) => {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    while (str.length % 4) str += '=';
    return atob(str);
  }
};

// Browser-compatible password hashing
function browserHash(password: string): string {
  // Simple hash for development - DO NOT use in production
  return btoa(password);
}

function browserVerify(password: string, hash: string): boolean {
  // Simple verification for development - DO NOT use in production
  return btoa(password) === hash;
}

export async function hashPassword(password: string): Promise<string> {
  return browserHash(password);
}

export async function verifyPassword(password: string, hash: string): Promise<boolean> {
  return browserVerify(password, hash);
}

export function generateToken(userId: string): string {
  const header = {
    alg: 'HS256',
    typ: 'JWT'
  };

  const payload = {
    userId,
    exp: Math.floor(Date.now() / 1000) + (7 * 24 * 60 * 60) // 7 days
  };

  const encodedHeader = base64url.encode(JSON.stringify(header));
  const encodedPayload = base64url.encode(JSON.stringify(payload));
  
  const signature = base64url.encode(
    Array.from(
      new Uint8Array(
        new TextEncoder().encode(`${encodedHeader}.${encodedPayload}${JWT_SECRET}`)
      )
    ).reduce((str, byte) => str + String.fromCharCode(byte), '')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
}

export function verifyToken(token: string): { userId: string } | null {
  try {
    const [encodedHeader, encodedPayload, signature] = token.split('.');
    
    // Verify signature
    const expectedSignature = base64url.encode(
      Array.from(
        new Uint8Array(
          new TextEncoder().encode(`${encodedHeader}.${encodedPayload}${JWT_SECRET}`)
        )
      ).reduce((str, byte) => str + String.fromCharCode(byte), '')
    );

    if (signature !== expectedSignature) {
      return null;
    }

    // Decode payload
    const payload = JSON.parse(base64url.decode(encodedPayload));
    
    // Check expiration
    if (payload.exp < Math.floor(Date.now() / 1000)) {
      return null;
    }

    return { userId: payload.userId };
  } catch {
    return null;
  }
}

export function validateEmail(email: string): boolean {
  return validator.isEmail(email);
}

export function validatePassword(password: string): { isValid: boolean; message: string } {
  if (password.length < 8) {
    return { isValid: false, message: 'Password must be at least 8 characters long' };
  }
  if (!/[A-Z]/.test(password)) {
    return { isValid: false, message: 'Password must contain at least one uppercase letter' };
  }
  if (!/[a-z]/.test(password)) {
    return { isValid: false, message: 'Password must contain at least one lowercase letter' };
  }
  if (!/[0-9]/.test(password)) {
    return { isValid: false, message: 'Password must contain at least one number' };
  }
  return { isValid: true, message: '' };
}

export function validatePhone(phone: string): boolean {
  try {
    const phoneNumber = parsePhoneNumber(phone, 'MA');
    return phoneNumber?.isValid() || false;
  } catch {
    return false;
  }
}

export function generateVerificationCode(): string {
  return Math.floor(100000 + Math.random() * 900000).toString();
}