import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types';
import { db } from '../db';
import { verifyToken } from '../utils/security';

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (userData: Partial<User>, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const payload = verifyToken(token);
          if (payload?.userId) {
            const user = await db.users.get(payload.userId);
            if (user) {
              setUser(user);
            } else {
              localStorage.removeItem('authToken');
            }
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('authToken');
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const user = await db.users.where('email').equals(email).first();
      
      if (!user) {
        throw new Error('Email ou mot de passe incorrect');
      }

      // Dans un vrai environnement, nous vérifierions le mot de passe haché
      // Pour le développement, on accepte simplement la connexion
      setUser(user);
      localStorage.setItem('authToken', 'dev-token');
    } catch (error) {
      throw new Error('La connexion a échoué');
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (userData: Partial<User>, password: string) => {
    setIsLoading(true);
    try {
      // Vérifier si l'email existe déjà
      const existingUser = await db.users.where('email').equals(userData.email!).first();
      if (existingUser) {
        throw new Error('Cet email est déjà utilisé');
      }

      // Créer l'utilisateur
      const userId = await db.users.add({
        ...userData,
        createdAt: new Date(),
        isEmailVerified: false,
        isPhoneVerified: false
      });

      const newUser = await db.users.get(userId);
      if (newUser) {
        setUser(newUser);
        localStorage.setItem('authToken', 'dev-token');
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw new Error('L\'inscription a échoué');
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      localStorage.removeItem('authToken');
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}