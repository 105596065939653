import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ProfileHeader } from './ProfileHeader';
import { ProfileForm } from './ProfileForm';
import { ProfileView } from './ProfileView';
import { ProfileFormData } from './types';

export function ProfilePage() {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<ProfileFormData>({
    name: user?.name || '',
    jobTitle: user?.jobTitle || '',
    bio: user?.bio || '',
    hourlyRate: user?.hourlyRate || '',
    skills: user?.skills?.join(', ') || '',
    languages: user?.languages?.join(', ') || '',
    education: user?.education || '',
    experience: user?.experience || '',
  });

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // TODO: Implement image upload to backend
      console.log('Uploading image:', file);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Implement profile update
    setIsEditing(false);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 mt-16">
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <ProfileHeader
          user={user}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onImageUpload={handleImageUpload}
        />
        
        <div className="pt-20 px-8 pb-8">
          {isEditing ? (
            <ProfileForm
              formData={formData}
              setFormData={setFormData}
              onSubmit={handleSubmit}
              setIsEditing={setIsEditing}
            />
          ) : (
            <ProfileView formData={formData} user={user} />
          )}
        </div>
      </div>
    </div>
  );
}