import React from 'react';
import { 
  Home, Users, Briefcase, DollarSign, 
  Settings, Bell, LogOut 
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export default function AdminNav() {
  const { logout } = useAuth();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold text-emerald-600">AjiTkhdm Admin</span>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavLink icon={Home} text="Dashboard" />
              <NavLink icon={Users} text="Users" />
              <NavLink icon={Briefcase} text="Jobs" />
              <NavLink icon={DollarSign} text="Payments" />
              <NavLink icon={Settings} text="Settings" />
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <button className="text-gray-400 hover:text-gray-500">
              <Bell className="h-6 w-6" />
            </button>
            <button
              onClick={logout}
              className="flex items-center text-gray-400 hover:text-gray-500"
            >
              <LogOut className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

function NavLink({ icon: Icon, text }: { icon: any; text: string }) {
  return (
    <a
      href="#"
      className="inline-flex items-center px-1 pt-1 text-gray-500 hover:text-gray-900"
    >
      <Icon className="h-5 w-5 mr-2" />
      {text}
    </a>
  );
}