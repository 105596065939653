import { User } from '../types';

// Mock email service for development
export const emailService = {
  async sendVerificationEmail(email: string, code: string): Promise<void> {
    // In development, just log the verification code
    console.log(`Development: Verification code for ${email}: ${code}`);
    
    // In production, this would integrate with a real email service
    if (process.env.NODE_ENV === 'production') {
      try {
        // Production email logic would go here
        // await realEmailService.send(...)
      } catch (error) {
        console.error('Email service error:', error);
        throw new Error('Failed to send verification email');
      }
    }
  },

  async sendPasswordResetEmail(email: string, token: string): Promise<void> {
    // In development, just log the reset token
    console.log(`Development: Password reset token for ${email}: ${token}`);
    
    // In production, this would integrate with a real email service
    if (process.env.NODE_ENV === 'production') {
      try {
        // Production email logic would go here
        // await realEmailService.send(...)
      } catch (error) {
        console.error('Email service error:', error);
        throw new Error('Failed to send password reset email');
      }
    }
  }
};