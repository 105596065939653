import React, { useState } from 'react';
import { X, Mail, Phone, AlertCircle, CheckCircle2 } from 'lucide-react';
import { verifyCode, resendVerification } from '../../db/operations/auth';

interface VerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  email: string;
  phone?: string;
}

export default function VerificationModal({ isOpen, onClose, userId, email, phone }: VerificationModalProps) {
  const [emailCode, setEmailCode] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState<{ email?: boolean; phone?: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleVerify = async (type: 'email' | 'phone') => {
    setError('');
    setIsLoading(true);

    try {
      await verifyCode({
        userId,
        code: type === 'email' ? emailCode : phoneCode,
        type
      });
      setSuccess(prev => ({ ...prev, [type]: true }));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (type: 'email' | 'phone') => {
    setError('');
    setIsLoading(true);

    try {
      await resendVerification(userId, type);
      alert(`New verification code sent to your ${type}`);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to resend code');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="h-6 w-6" />
        </button>

        <h2 className="text-2xl font-bold text-gray-900 mb-6">Verify Your Account</h2>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        <div className="space-y-6">
          {/* Email Verification */}
          <div className={`p-4 rounded-lg ${success.email ? 'bg-green-50' : 'bg-gray-50'}`}>
            <div className="flex items-center mb-4">
              <Mail className="h-5 w-5 mr-2 text-gray-500" />
              <h3 className="font-medium text-gray-900">Email Verification</h3>
              {success.email && (
                <CheckCircle2 className="h-5 w-5 ml-2 text-green-500" />
              )}
            </div>

            {!success.email && (
              <>
                <p className="text-sm text-gray-600 mb-4">
                  Enter the code sent to {email}
                </p>
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={emailCode}
                    onChange={(e) => setEmailCode(e.target.value)}
                    placeholder="Enter code"
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
                    maxLength={6}
                  />
                  <button
                    onClick={() => handleVerify('email')}
                    disabled={isLoading || emailCode.length !== 6}
                    className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors disabled:opacity-50"
                  >
                    Verify
                  </button>
                </div>
                <button
                  onClick={() => handleResend('email')}
                  disabled={isLoading}
                  className="mt-2 text-sm text-emerald-600 hover:text-emerald-700"
                >
                  Resend Code
                </button>
              </>
            )}
          </div>

          {/* Phone Verification */}
          {phone && (
            <div className={`p-4 rounded-lg ${success.phone ? 'bg-green-50' : 'bg-gray-50'}`}>
              <div className="flex items-center mb-4">
                <Phone className="h-5 w-5 mr-2 text-gray-500" />
                <h3 className="font-medium text-gray-900">Phone Verification</h3>
                {success.phone && (
                  <CheckCircle2 className="h-5 w-5 ml-2 text-green-500" />
                )}
              </div>

              {!success.phone && (
                <>
                  <p className="text-sm text-gray-600 mb-4">
                    Enter the code sent to {phone}
                  </p>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      value={phoneCode}
                      onChange={(e) => setPhoneCode(e.target.value)}
                      placeholder="Enter code"
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-emerald-500 focus:border-emerald-500"
                      maxLength={6}
                    />
                    <button
                      onClick={() => handleVerify('phone')}
                      disabled={isLoading || phoneCode.length !== 6}
                      className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors disabled:opacity-50"
                    >
                      Verify
                    </button>
                  </div>
                  <button
                    onClick={() => handleResend('phone')}
                    disabled={isLoading}
                    className="mt-2 text-sm text-emerald-600 hover:text-emerald-700"
                  >
                    Resend Code
                  </button>
                </>
              )}
            </div>
          )}
        </div>

        {Object.values(success).every(Boolean) && (
          <div className="mt-6 text-center">
            <p className="text-green-600 font-medium">
              All verifications complete! You can now close this window.
            </p>
            <button
              onClick={onClose}
              className="mt-4 px-6 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors"
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
}