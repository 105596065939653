import { db } from '../schema';
import type { Job } from '../../types';

export async function getJobs(filters?: Partial<Job>): Promise<Job[]> {
  let collection = db.jobs.orderBy('createdAt').reverse();
  
  if (filters) {
    if (filters.status) collection = collection.filter(job => job.status === filters.status);
    if (filters.category) collection = collection.filter(job => job.category === filters.category);
    if (filters.clientId) collection = collection.filter(job => job.clientId === filters.clientId);
    if (filters.workerId) collection = collection.filter(job => job.workerId === filters.workerId);
    if (filters.type) collection = collection.filter(job => job.type === filters.type);
    if (filters.location) collection = collection.filter(job => job.location === filters.location);
  }
  
  return await collection.toArray();
}

export async function getJob(id: string): Promise<Job | undefined> {
  return await db.jobs.get(id);
}

export async function createJob(jobData: Omit<Job, 'id' | 'createdAt' | 'status'>): Promise<string> {
  const totalAmount = calculateTotalAmount(jobData);
  
  return await db.jobs.add({
    ...jobData,
    salary: {
      ...jobData.salary,
      totalAmount
    },
    status: 'open',
    createdAt: new Date()
  }) as string;
}

export async function updateJob(id: string, updates: Partial<Job>): Promise<number> {
  return await db.jobs.update(id, updates);
}

export async function deleteJob(id: string): Promise<void> {
  await db.jobs.delete(id);
}

export async function searchJobs(query: string): Promise<Job[]> {
  const searchTerms = query.toLowerCase().split(' ');
  
  return await db.jobs
    .orderBy('createdAt')
    .reverse()
    .filter(job => {
      const searchText = `${job.title} ${job.description} ${job.category} ${job.location} ${job.skills.join(' ')}`.toLowerCase();
      return searchTerms.every(term => searchText.includes(term));
    })
    .toArray();
}

function calculateTotalAmount(jobData: Partial<Job>): number {
  if (!jobData.salary?.amount || !jobData.workSchedule) return 0;

  const amount = Number(jobData.salary.amount);
  const duration = Number(jobData.workSchedule.duration);
  
  switch (jobData.workSchedule.durationType) {
    case 'days':
      return amount * duration;
    case 'weeks':
      return amount * duration * (Number(jobData.workSchedule.daysPerWeek) || 5);
    case 'months':
      return amount * duration * (Number(jobData.workSchedule.daysPerWeek) || 5) * 4;
    default:
      return amount;
  }
}