import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { db } from '../../db/schema';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function PaymentChart() {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const loadPaymentData = async () => {
      const payments = await db.payments.toArray();
      
      // Group payments by date
      const groupedData = payments.reduce((acc: any, payment) => {
        const date = new Date(payment.createdAt).toLocaleDateString();
        acc[date] = (acc[date] || 0) + payment.platformFee;
        return acc;
      }, {});

      // Sort by date
      const sortedDates = Object.keys(groupedData).sort();
      
      setChartData({
        labels: sortedDates,
        datasets: [
          {
            label: 'Platform Revenue (MAD)',
            data: sortedDates.map(date => groupedData[date]),
            borderColor: 'rgb(16, 185, 129)',
            backgroundColor: 'rgba(16, 185, 129, 0.1)',
            tension: 0.4
          }
        ]
      });
    };

    loadPaymentData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  if (!chartData) return null;

  return <Line data={chartData} options={options} />;
}