import { db } from '../schema';
import type { AuthUser, LoginCredentials, RegisterData, VerificationData } from '../../types/auth';
import { 
  hashPassword, 
  verifyPassword, 
  generateToken, 
  validateEmail, 
  validatePassword,
  validatePhone,
  generateVerificationCode 
} from '../../utils/security';
import { emailService } from '../../services/email';

export async function register(data: RegisterData): Promise<{ userId: string; token: string }> {
  // Validate input
  if (!validateEmail(data.email)) {
    throw new Error('Invalid email address');
  }

  const passwordValidation = validatePassword(data.password);
  if (!passwordValidation.isValid) {
    throw new Error(passwordValidation.message);
  }

  if (data.phone && !validatePhone(data.phone)) {
    throw new Error('Invalid phone number');
  }

  // Check if user exists
  const existingUser = await db.users.where('email').equals(data.email).first();
  if (existingUser) {
    throw new Error('Email already registered');
  }

  // Create verification codes
  const emailCode = generateVerificationCode();
  const phoneCode = data.phone ? generateVerificationCode() : undefined;

  // Hash password and create user
  const passwordHash = await hashPassword(data.password);
  
  const userData = {
    email: data.email,
    phone: data.phone,
    passwordHash,
    isEmailVerified: false,
    isPhoneVerified: false,
    verificationCodes: {
      email: {
        code: emailCode,
        expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000) // 24 hours
      },
      ...(phoneCode && {
        phone: {
          code: phoneCode,
          expiresAt: new Date(Date.now() + 10 * 60 * 1000) // 10 minutes
        }
      })
    },
    name: data.name,
    role: data.role,
    location: data.location,
    createdAt: new Date()
  };

  const userId = await db.users.add(userData);

  // Send verification email
  try {
    await emailService.sendVerificationEmail(data.email, emailCode);
  } catch (error) {
    console.warn('Verification email not sent:', error);
    // Continue registration process even if email fails
  }

  const token = generateToken(userId.toString());
  return { userId: userId.toString(), token };
}

export async function login(credentials: LoginCredentials): Promise<{ userId: string; token: string }> {
  const user = await db.users.where('email').equals(credentials.email).first();
  if (!user) {
    throw new Error('Invalid credentials');
  }

  const isValid = await verifyPassword(credentials.password, user.passwordHash);
  if (!isValid) {
    throw new Error('Invalid credentials');
  }

  const token = generateToken(user.id);
  return { userId: user.id, token };
}

export async function verifyCode({ userId, code, type }: VerificationData): Promise<void> {
  const user = await db.users.get(userId);
  if (!user) {
    throw new Error('User not found');
  }

  const verification = user.verificationCodes?.[type];
  if (!verification) {
    throw new Error('No verification pending');
  }

  if (new Date() > new Date(verification.expiresAt)) {
    throw new Error('Verification code expired');
  }

  if (verification.code !== code) {
    throw new Error('Invalid verification code');
  }

  // Mark as verified and remove code
  await db.users.update(userId, {
    [`is${type === 'email' ? 'Email' : 'Phone'}Verified`]: true,
    verificationCodes: {
      ...user.verificationCodes,
      [type]: undefined
    }
  });
}

export async function resendVerification(userId: string, type: 'email' | 'phone'): Promise<void> {
  const user = await db.users.get(userId);
  if (!user) {
    throw new Error('User not found');
  }

  const newCode = generateVerificationCode();
  const expiresAt = new Date(
    Date.now() + (type === 'email' ? 24 * 60 * 60 * 1000 : 10 * 60 * 1000)
  );

  await db.users.update(userId, {
    verificationCodes: {
      ...user.verificationCodes,
      [type]: { code: newCode, expiresAt }
    }
  });

  if (type === 'email') {
    try {
      await emailService.sendVerificationEmail(user.email, newCode);
    } catch (error) {
      console.warn('Verification email not sent:', error);
      // Continue the process even if email fails
    }
  } else {
    console.log(`SMS verification code for ${user.phone}: ${newCode}`);
  }
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
  const user = await db.users.where('email').equals(email).first();
  if (!user) {
    // Don't reveal whether the email exists
    return;
  }

  const resetToken = generateToken(user.id);
  const expiresAt = new Date(Date.now() + 60 * 60 * 1000); // 1 hour

  await db.users.update(user.id, {
    passwordReset: {
      token: resetToken,
      expiresAt
    }
  });

  try {
    await emailService.sendPasswordResetEmail(email, resetToken);
  } catch (error) {
    console.warn('Password reset email not sent:', error);
    throw new Error('Failed to send password reset email');
  }
}

export async function resetPassword(token: string, newPassword: string): Promise<void> {
  const user = await db.users
    .filter(u => u.passwordReset?.token === token)
    .first();

  if (!user || !user.passwordReset || new Date() > new Date(user.passwordReset.expiresAt)) {
    throw new Error('Invalid or expired reset token');
  }

  const passwordValidation = validatePassword(newPassword);
  if (!passwordValidation.isValid) {
    throw new Error(passwordValidation.message);
  }

  const passwordHash = await hashPassword(newPassword);

  await db.users.update(user.id, {
    passwordHash,
    passwordReset: undefined
  });
}