import React, { useState, useEffect } from 'react';
import { Clock, MapPin, DollarSign, ArrowRight, MessageCircle, SearchX, Calendar, Trash2 } from 'lucide-react';
import { Job, User } from '../types';
import { getJobs, getUser, deleteJob } from '../db';
import ChatWindow from './chat/ChatWindow';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';

interface JobListProps {
  jobs?: Job[];
  searchPerformed?: boolean;
}

export default function JobList({ jobs: propJobs, searchPerformed = false }: JobListProps) {
  const { user: currentUser } = useAuth();
  const { t } = useLanguage();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [selectedEmployerId, setSelectedEmployerId] = useState<string | null>(null);
  const [showChat, setShowChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadJobs = async () => {
      setIsLoading(true);
      try {
        if (propJobs) {
          setJobs(propJobs);
        } else {
          const loadedJobs = await getJobs({ status: 'open' });
          setJobs(loadedJobs);
        }
      } catch (error) {
        console.error('Error loading jobs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadJobs();
  }, [propJobs]);

  const handleContactEmployer = async (jobId: string, employerId: string) => {
    if (!currentUser) {
      alert('Veuillez vous connecter pour contacter l\'employeur');
      return;
    }

    if (currentUser.role !== 'worker') {
      alert('Seuls les travailleurs peuvent postuler aux offres');
      return;
    }

    setSelectedJobId(jobId);
    setSelectedEmployerId(employerId);
    setShowChat(true);
  };

  const handleDeleteJob = async (jobId: string) => {
    if (!currentUser) return;
    
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette annonce ?')) {
      try {
        await deleteJob(jobId);
        setJobs(jobs.filter(job => job.id !== jobId));
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (searchPerformed && jobs.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <SearchX className="h-16 w-16 mb-4 text-gray-500" />
        <h3 className="text-xl font-semibold mb-2">Aucune offre trouvée</h3>
        <p>Essayez de modifier vos critères de recherche</p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 space-y-6 mb-16">
      {jobs.map((job) => (
        <div key={job.id} className="bg-white rounded-lg shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow">
          <div className="flex justify-between items-start">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{job.title}</h3>
              
              <div className="flex flex-wrap gap-4 text-sm text-gray-500 mb-4">
                <div className="flex items-center">
                  <MapPin className="h-4 w-4 mr-1" />
                  {job.location} • {t(job.type)}
                </div>
                <div className="flex items-center">
                  <DollarSign className="h-4 w-4 mr-1" />
                  {job.salary?.amount} {job.salary?.currency}/{job.salary?.type || 'hour'}
                  {job.salary?.totalAmount && (
                    <span className="ml-1 text-emerald-600">
                      (Total: {job.salary.totalAmount} {job.salary.currency})
                    </span>
                  )}
                </div>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {job.workSchedule?.type === 'fixed' 
                    ? `${job.workSchedule.hours?.start || '9:00'} - ${job.workSchedule.hours?.end || '17:00'}`
                    : 'Horaires flexibles'
                  }
                </div>
                {job.workSchedule?.duration && (
                  <div className="flex items-center">
                    <Calendar className="h-4 w-4 mr-1" />
                    {job.workSchedule.duration} {job.workSchedule.durationType}
                  </div>
                )}
              </div>

              <p className="text-gray-600 mb-4">{job.description}</p>
              
              {/* Benefits Section */}
              {job.benefits && (
                <div className="mb-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Avantages :</h4>
                  <div className="flex flex-wrap gap-2">
                    {job.benefits.equipment && (
                      <span className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm">
                        Équipement fourni
                      </span>
                    )}
                    {job.benefits.transport && (
                      <span className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm">
                        Transport fourni
                      </span>
                    )}
                    {job.benefits.meals && (
                      <span className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm">
                        Repas fournis
                      </span>
                    )}
                  </div>
                  {job.benefits.other && (
                    <p className="text-sm text-gray-600 mt-2">{job.benefits.other}</p>
                  )}
                </div>
              )}

              <div className="flex flex-wrap gap-2 mb-4">
                {job.skills.map((skill) => (
                  <span key={skill} className="bg-emerald-50 text-emerald-600 px-3 py-1 rounded-full text-sm">
                    {skill}
                  </span>
                ))}
              </div>

              <div className="flex gap-4">
                {currentUser?.role === 'worker' && (
                  <button
                    onClick={() => handleContactEmployer(job.id!, job.clientId)}
                    className="flex items-center text-emerald-600 hover:text-emerald-700"
                  >
                    <MessageCircle className="h-4 w-4 mr-1" />
                    Contacter l'employeur
                  </button>
                )}
                {currentUser?.id === job.clientId && (
                  <button
                    onClick={() => handleDeleteJob(job.id!)}
                    className="flex items-center text-red-600 hover:text-red-700"
                  >
                    <Trash2 className="h-4 w-4 mr-1" />
                    Supprimer l'annonce
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {showChat && selectedEmployerId && (
        <ChatWindow
          receiverId={selectedEmployerId}
          jobId={selectedJobId}
          onClose={() => {
            setShowChat(false);
            setSelectedJobId(null);
            setSelectedEmployerId(null);
          }}
        />
      )}
    </div>
  );
}