import React, { createContext, useContext, useState } from 'react';

type Language = 'en' | 'darija';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
}

const translations = {
  en: {
    'app.name': 'AjiTkhdm',
    'app.tagline': 'Find Work or Hire Workers in Morocco',
    'app.description': 'Connect with skilled professionals or find your next opportunity',
    'action.postJob': 'Post a Job',
    'action.findWork': 'Find Work',
    'action.search': 'Search for services...',
    'action.login': 'Login',
    'action.logout': 'Sign Out',
    'categories.title': 'Popular Categories',
    'categories.jobCount': 'jobs',
    'category.development': 'Development',
    'category.design': 'Design',
    'category.marketing': 'Digital Marketing',
    'category.content': 'Content Creation',
    'category.mechanics': 'Mechanics',
    'category.construction': 'Construction',
    'category.carpentry': 'Carpentry',
    'category.tailoring': 'Tailoring',
    'category.cooking': 'Cooking',
    'category.drivers': 'Drivers',
    'category.delivery': 'Delivery',
    'category.cleaning': 'House Cleaning',
    'category.gardening': 'Gardening',
    'category.laundry': 'Laundry',
    'category.childcare': 'Childcare',
    'category.security': 'Security',
    'category.sales': 'Sales',
    'category.training': 'Personal Training',
    'category.tech': 'Tech Support',
    'footer.howToHire': 'How to Hire',
    'footer.pricing': 'Pricing',
    'footer.enterprise': 'Enterprise',
    'footer.success': 'Success Stories',
    'footer.howToWork': 'How to Find Work',
    'footer.contracts': 'Direct Contracts',
    'footer.profile': 'Profile Creation',
    'footer.resources': 'Resources',
    'footer.help': 'Help & Support',
    'footer.blog': 'Blog',
    'footer.community': 'Community',
    'footer.contact': 'Contact Us',
    'footer.rights': 'All rights reserved.',
    'account.employer': 'For Employers',
    'account.worker': 'For Workers'
  },
  darija: {
    'app.name': 'أجي تخدم',
    'app.tagline': 'لقا خدمة ولا خدام فالمغرب',
    'app.description': 'كنربطو الخدامة مع المخدمين بطريقة سهلة ومضمونة',
    'action.postJob': 'خدم شي حد',
    'action.findWork': 'قلب على خدمة',
    'action.search': 'قلب على شي خدمة...',
    'action.login': 'دخل',
    'action.logout': 'خرج',
    'categories.title': 'أنواع الخدمة',
    'categories.jobCount': 'خدمة',
    'category.development': 'برمجة',
    'category.design': 'تصميم',
    'category.marketing': 'تسويق رقمي',
    'category.content': 'صناعة المحتوى',
    'category.mechanics': 'ميكانيك',
    'category.construction': 'بناء',
    'category.carpentry': 'نجارة',
    'category.tailoring': 'خياطة',
    'category.cooking': 'طياب',
    'category.drivers': 'شوفور',
    'category.delivery': 'ليفري',
    'category.cleaning': 'خدامة',
    'category.gardening': 'جرنان',
    'category.laundry': 'صباغة',
    'category.childcare': 'مربية',
    'category.security': 'گارديان',
    'category.sales': 'بياع',
    'category.training': 'كوتش',
    'category.tech': 'تقني',
    'footer.howToHire': 'كيفاش تخدم شي حد',
    'footer.pricing': 'الأثمنة',
    'footer.enterprise': 'الشركات',
    'footer.success': 'قصص النجاح',
    'footer.howToWork': 'كيفاش تلقا خدمة',
    'footer.contracts': 'العقود المباشرة',
    'footer.profile': 'إنشاء الحساب',
    'footer.resources': 'مصادر مفيدة',
    'footer.help': 'المساعدة والدعم',
    'footer.blog': 'المدونة',
    'footer.community': 'المجتمع',
    'footer.contact': 'اتصل بنا',
    'footer.rights': 'جميع الحقوق محفوظة.',
    'account.employer': 'للمخدمين',
    'account.worker': 'للخدامة'
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('en');

  const t = (key: string): string => {
    return translations[language][key as keyof typeof translations[typeof language]] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}