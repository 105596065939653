export const moroccanCities = {
  casablanca: {
    name: 'Casablanca',
    districts: [
      'Anfa',
      'Ain Diab',
      'Bourgogne',
      'Gauthier',
      'Maarif',
      'Racine',
      'Sidi Belyout',
      'Ain Chock',
      'Hay Hassani',
      'Ain Sebaa',
      'Sidi Bernoussi',
      'Ben M\'Sick',
      'Sbata',
      'Sidi Moumen',
      'Moulay Rachid',
      'Dar Bouazza',
      'Errahma',
      'Bouskoura',
      'Lissasfa',
      'Oulfa',
      'Sidi Maarouf',
      'Tit Mellil'
    ]
  },
  rabat: {
    name: 'Rabat',
    districts: [
      'Agdal',
      'Hassan',
      'Hay Riad',
      'Les Orangers',
      'Médina',
      'Ocean',
      'Souissi',
      'Takkadoum',
      'Yacoub El Mansour',
      'Youssoufia',
      'Témara',
      'Salé',
      'Hay El Fath',
      'Aviation',
      'Diour Jamaa',
      'Akkari'
    ]
  },
  marrakech: {
    name: 'Marrakech',
    districts: [
      'Médina',
      'Guéliz',
      'Hivernage',
      'Amerchich',
      'Massira',
      'Targa',
      'Route de Casablanca',
      'Route de l\'Ourika',
      'Route de Fès',
      'Palmeraie',
      'Annakhil',
      'Sidi Ghanem',
      'Semlalia',
      'Sidi Youssef Ben Ali',
      'Daoudiate',
      'Hay Mohammadi'
    ]
  },
  other: [
    'Tanger',
    'Fès',
    'Meknès',
    'Oujda',
    'Kénitra',
    'Agadir',
    'Tétouan',
    'Safi',
    'El Jadida',
    'Mohammedia',
    'Béni Mellal',
    'Nador',
    'Khouribga',
    'Settat',
    'Berrechid',
    'Taza',
    'Khemisset',
    'Essaouira',
    'Ouarzazate'
  ]
};